import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';

import Dashboard from '../pages/Dashboard';

import Profile from '../pages/Profile';

import UserList from '~/pages/Users/List';
import UserCreate from '~/pages/Users/Create';
import UserEdit from '~/pages/Users/Edit';

import GroupList from '~/pages/Groups/List';
import GroupCreate from '~/pages/Groups/Create';
import GroupEdit from '~/pages/Groups/Edit';
import GroupViewData from '~/pages/Groups/ViewData';

import BrokerList from '~/pages/Brokers/List';
import BrokerCreate from '~/pages/Brokers/Create';
import BrokerEdit from '~/pages/Brokers/Edit';

import BrokerData from '~/pages/Brokers/Data';
import BrokerDataEdit from '~/pages/Brokers/EditData';
import BrokerViewData from '~/pages/Brokers/ViewData';

import ViewAll from '~/pages/ViewAll';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />

      <Route path="/profile" exact component={Profile} isPrivate />

      <Route path="/dashboard" exact component={Dashboard} isPrivate />

      <Route path="/users" exact component={UserList} isPrivate administrator />
      <Route
        path="/users/create"
        component={UserCreate}
        isPrivate
        administrator
      />
      <Route
        path="/users/:id/edit"
        component={UserEdit}
        isPrivate
        administrator
      />

      <Route
        path="/groups"
        exact
        component={GroupList}
        isPrivate
        administrator
      />

      <Route
        path="/groups/:id/data"
        component={GroupViewData}
        isPrivate
        double
      />

      <Route
        path="/groups/create"
        component={GroupCreate}
        isPrivate
        administrator
      />
      <Route
        path="/groups/:id/edit"
        component={GroupEdit}
        isPrivate
        administrator
      />

      <Route
        path="/brokers"
        exact
        component={BrokerList}
        isPrivate
        administrator
      />
      <Route
        path="/brokers/create"
        component={BrokerCreate}
        isPrivate
        administrator
      />
      <Route
        path="/brokers/:id/edit"
        component={BrokerEdit}
        isPrivate
        administrator
      />

      <Route path="/brokers/data" component={BrokerData} isPrivate />
      <Route
        path="/brokers/:id/edit-data"
        component={BrokerDataEdit}
        isPrivate
      />
      <Route path="/brokers/:id/data" component={BrokerViewData} isPrivate />

      <Route path="/view-all" component={ViewAll} isPrivate administrator />
    </Switch>
  );
}
