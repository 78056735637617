import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function DefaultContainer({ minHeight, children }) {
  return <Container minHeight={minHeight}>{children}</Container>;
}

DefaultContainer.defaultProps = {
  minHeight: '300px',
  children: null,
};

DefaultContainer.propTypes = {
  minHeight: PropTypes.string,
  children: PropTypes.any,
};
