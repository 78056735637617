import styled from 'styled-components';
import { darken } from 'polished';

export const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #008891;
  border: 0;
  border-radius: 4px;

  &:hover {
    background: ${darken(0.07, '#008891')};
  }
`;
