/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RiAdminLine } from 'react-icons/ri';
import { FaRegUser, FaUserTie } from 'react-icons/fa';
import roundTo from 'round-to';

import api from '~/services/api';

import { DivButtons, InputText } from './styles';

import Container from '~/components/Default/Container';
import Loader from '~/components/Loader';
import Table from '~/components/Table';
import Pagination from '~/components/Pagination';
import CreateButton from '~/components/Form/Button/Create';
import EditButton from '~/components/Form/Button/Edit';
import DeleteButton from '~/components/Form/Button/Delete';
import CustomButton from '~/components/Form/Button/Custom';
import Modal from '~/components/Modal/Delete';

export default function ListUser() {
  const [users, setUsers] = useState([]);
  const [loaderUsers, setLoaderUsers] = useState(true);
  const [deleteUser, setDeleteUser] = useState(null);
  const [showModalUser, setShowModalUser] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const { profile } = useSelector((state) => state.user);

  function handleUserModal(id) {
    setDeleteUser(id);
    setShowModalUser(!showModalUser);
  }

  const getTypeUser = (type) => {
    if (type === 'administrator')
      return (
        <CustomButton color="#f6c065">
          <RiAdminLine color="#fff" size={21} />
        </CustomButton>
      );

    if (type === 'manager')
      return (
        <CustomButton color="#16c79a">
          <FaUserTie color="#fff" size={20} />
        </CustomButton>
      );

    if (type === 'broker')
      return (
        <CustomButton color="#f05454">
          <FaRegUser color="#fff" size={20} />
        </CustomButton>
      );

    return null;
  };

  const changePage = (num) => {
    setPage(num + 1);
  };

  const getUsers = async () => {
    setLoaderUsers(true);

    try {
      const response = await api.get('/users', {
        params: {
          page,
          search,
        },
      });

      setUsers(
        response.data.rows.map((user) => ({
          id: user.id,
          name: user.name,
          email: user.email,
          type: getTypeUser(user.type),
          edit: <EditButton url={`/users/${user.id}/edit`} />,
          delete: <DeleteButton func={() => handleUserModal(user.id)} />,
        }))
      );

      const numberOfPages = roundTo.up(response.data.count / 10, 0);

      setCount(numberOfPages);
    } catch (err) {
      toast.error('Erro ao carregar usuários!');
    } finally {
      setLoaderUsers(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [page, search]);

  async function handleDeleteUser() {
    try {
      setShowModalUser(false);
      if (profile.id !== deleteUser) {
        setLoaderUsers(true);

        await api.delete(`/users/${deleteUser}`);

        setUsers(users.filter((user) => user.id !== deleteUser));

        toast.success('Usuário excluído com sucesso.');
      } else {
        toast.warn('Você não pode deletar o próprio usuário.');
      }
    } catch (err) {
      toast.error('Erro ao excluir usuário, confira os dados!');
    } finally {
      setLoaderUsers(false);
      setDeleteUser(null);
    }
  }

  const headerUsers = ['Nome', 'E-mail', 'Tipo', 'Editar', 'Deletar'];

  return (
    <>
      <Modal
        show={showModalUser}
        setState={setShowModalUser}
        func={handleDeleteUser}
      />

      <Container>
        <DivButtons>
          <CreateButton url="/users/create" text="Adicionar usuário" />

          <InputText
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </DivButtons>

        {loaderUsers ? (
          <Loader />
        ) : (
          <>
            <Table
              title="Usuários"
              header={headerUsers}
              data={users}
              size="500px"
            />

            <Pagination
              numberOfPages={count}
              activePage={page}
              changePage={changePage}
            />
          </>
        )}
      </Container>
    </>
  );
}
