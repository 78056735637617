import styled from 'styled-components';

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  h1 {
    font-size: 24px;
    line-height: 31px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    color: #008891;
    margin-bottom: 20px;
  }

  p {
    width: 100%;
    text-indent: 20px;
    font-size: 21px;
    line-height: 27px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
    color: #323232;
  }
`;
