/* eslint-disable no-loop-func */
import React from 'react';
import PropTypes from 'prop-types';
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';

import { Container, PaginationButton } from './styles';

export default function Pagination({ numberOfPages, activePage, changePage }) {
  const nextPage = (num) => {
    const val = activePage + num;

    if (val > 0 && val <= numberOfPages) {
      changePage(val - 1);
    }
  };

  const previousPage = (num) => {
    const val = activePage + num;

    if (val > 0 && val <= numberOfPages) {
      changePage(val - 1);
    }
  };

  const renderButtons = () => {
    const items = [];

    if (activePage > 1) {
      items.push(
        <PaginationButton key="initialButton" onClick={() => nextPage(-1)}>
          <FiChevronsLeft />
        </PaginationButton>
      );
    }

    let length = numberOfPages;
    if (activePage + 4 < numberOfPages) length = activePage + 4;

    let i = 0;
    if (length - 5 > 0) i = length - 5;

    for (i; i < length; i += 1) {
      const num = i;
      items.push(
        <PaginationButton
          key={`button_${i}`}
          onClick={() => changePage(num)}
          disabled={i + 1 === activePage}
        >
          {i + 1}
        </PaginationButton>
      );
    }

    if (numberOfPages > 1 && activePage < numberOfPages) {
      items.push(
        <PaginationButton key="FinalButton" onClick={() => previousPage(1)}>
          <FiChevronsRight />
        </PaginationButton>
      );
    }

    return items;
  };

  return <Container>{renderButtons()}</Container>;
}

Pagination.propTypes = {
  numberOfPages: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
};
