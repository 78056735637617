import React from 'react';
import PropTypes from 'prop-types';

import { Container, CreateTable } from './styles';

export default function Table({ title, header, data, func, size }) {
  const handleGetKeys = () => {
    if (data.length > 0)
      return Object.keys(data[0]).filter((item) => item !== 'id');

    return [];
  };

  const handleGetHeader = () => {
    const keys = header;
    return keys.map((key) => {
      return <th key={key}>{key.toUpperCase()}</th>;
    });
  };

  const RenderRow = ({ content, keys }) => {
    return keys.map((key) => {
      return <td key={Math.random() * 111}>{content[key]}</td>;
    });
  };

  const handleGetRowsData = () => {
    const items = data;
    const keys = handleGetKeys();
    return items.map((row, index) => {
      return (
        <tr key={index} onClick={func ? () => func(row.id_) : null}>
          <RenderRow key={index} content={row} keys={keys} />
        </tr>
      );
    });
  };

  return (
    <Container>
      <h1>{title}</h1>

      <CreateTable size={size}>
        <thead>
          <tr>{handleGetHeader()}</tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            handleGetRowsData()
          ) : (
            <tr>
              <td>
                <div>
                  <p>Nenhum registro encontrado</p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </CreateTable>
    </Container>
  );
}

Table.defaultProps = {
  data: [],
  func: null,
};

Table.propTypes = {
  title: PropTypes.string.isRequired,
  header: PropTypes.array.isRequired,
  data: PropTypes.array,
  func: PropTypes.func,
  size: PropTypes.string.isRequired,
};
