/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import roundTo from 'round-to';
import PropTypes from 'prop-types';
import { format, subMonths } from 'date-fns';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';

import api from '~/services/api';

import { Title, FilterDate, ChartDiv, ScaleFilter, DivLegend } from './styles';

import Container from '~/components/Default/Container';

export default function ViewAll({ match }) {
  const [vgv, setVgv] = useState([]);
  const [vgvInitialDate, setVgvInitialDate] = useState(
    format(subMonths(new Date(), 11), 'uuuu-MM-dd')
  );
  const [vgvFinalDate, setVgvFinalDate] = useState(
    format(new Date(), 'uuuu-MM-dd')
  );
  const [vgvBreak, setVgvBreak] = useState(10000);
  const [vgvMax, setVgvMax] = useState(80000);
  const [tender, setTender] = useState([]);
  const [tenderInitialDate, setTenderInitialDate] = useState(
    format(subMonths(new Date(), 11), 'uuuu-MM-dd')
  );
  const [tenderFinalDate, setTenderFinalDate] = useState(
    format(new Date(), 'uuuu-MM-dd')
  );
  const [tenderBreak, setTenderBreak] = useState(25);
  const [tenderMax, setTenderMax] = useState(160);
  const [closing, setClosing] = useState([]);
  const [visit, setVisit] = useState([]);
  const [visitInitialDate, setVisitInitialDate] = useState(
    format(subMonths(new Date(), 11), 'uuuu-MM-dd')
  );
  const [visitFinalDate, setVisitFinalDate] = useState(
    format(new Date(), 'uuuu-MM-dd')
  );
  const [lead, setLead] = useState([]);
  const [leadInitialDate, setLeadInitialDate] = useState(
    format(subMonths(new Date(), 11), 'uuuu-MM-dd')
  );
  const [leadFinalDate, setLeadFinalDate] = useState(
    format(new Date(), 'uuuu-MM-dd')
  );
  const [leadBreak, setLeadBreak] = useState(300);
  const [leadMax, setLeadMax] = useState(1800);
  const [minute, setMinute] = useState([]);
  const [minuteInitialDate, setMinuteInitialDate] = useState(
    format(subMonths(new Date(), 11), 'uuuu-MM-dd')
  );
  const [minuteFinalDate, setMinuteFinalDate] = useState(
    format(new Date(), 'uuuu-MM-dd')
  );
  const [minuteBreak, setMinuteBreak] = useState(3);
  const [minuteMax, setMinuteMax] = useState(15);
  const [conversion, setConversion] = useState([]);
  const [capture, setCapture] = useState([]);
  const [captureInitialDate, setCaptureInitialDate] = useState(
    format(subMonths(new Date(), 11), 'uuuu-MM-dd')
  );
  const [captureFinalDate, setCaptureFinalDate] = useState(
    format(new Date(), 'uuuu-MM-dd')
  );
  const [captureBreak, setCaptureBreak] = useState(25);
  const [captureMax, setCaptureMax] = useState(160);
  const [id] = useState(match.params.id ? match.params.id : false);

  const getVgv = async () => {
    try {
      const response = await api.get(`/vgv/${id}`, {
        params: {
          initialDate: vgvInitialDate,
          finalDate: vgvFinalDate,
        },
      });

      setVgv(response.data);
    } catch (err) {
      toast.error('Erro ao carregar vgv!');
    }
  };

  const getTenderSubscriptions = async () => {
    try {
      const response = await api.get(`/tenders/${id}`, {
        params: {
          initialDate: tenderInitialDate,
          finalDate: tenderFinalDate,
        },
      });

      setTender(response.data);

      setClosing([
        {
          id: 'Read',
          data: response.data.map((item) => ({
            x: item.date,
            y: item.closings,
          })),
        },
      ]);
    } catch (err) {
      toast.error('Erro ao carregar propostas!');
    }
  };

  const getVisits = async () => {
    try {
      const response = await api.get(`/visits/${id}`, {
        params: {
          initialDate: visitInitialDate,
          finalDate: visitFinalDate,
        },
      });

      setVisit([
        {
          id: 'Leads',
          color: '#00587a',
          data: response.data.map((item) => ({
            x: item.date,
            y: item.leads,
          })),
        },
        {
          id: 'Propostas',
          color: '#0f3057',
          data: response.data.map((item) => ({
            x: item.date,
            y: item.tenders,
          })),
        },
      ]);
    } catch (err) {
      toast.error('Erro ao carregar visitas!');
    }
  };

  const getLeads = async () => {
    try {
      const response = await api.get(`/leads/${id}`, {
        params: {
          initialDate: leadInitialDate,
          finalDate: leadFinalDate,
        },
      });

      setLead(response.data);
    } catch (err) {
      toast.error('Erro ao carregar leads!');
    }
  };

  const getMinutesConversion = async () => {
    try {
      const response = await api.get(`/minutes/${id}`, {
        params: {
          initialDate: minuteInitialDate,
          finalDate: minuteFinalDate,
        },
      });

      setMinute(response.data);

      setConversion([
        {
          id: 'Read',
          data: response.data.map((item) => ({
            x: item.date,
            y: item.conversion,
          })),
        },
      ]);
    } catch (err) {
      toast.error('Erro ao carregar minutos!');
    }
  };

  const getCapture = async () => {
    try {
      const response = await api.get(`/capture/${id}`, {
        params: {
          initialDate: captureInitialDate,
          finalDate: captureFinalDate,
        },
      });

      setCapture(response.data);
    } catch (err) {
      toast.error('Erro ao carregar captação!');
    }
  };

  useEffect(() => {
    getVgv();
  }, [vgvFinalDate, vgvInitialDate]);

  useEffect(() => {
    getTenderSubscriptions();
  }, [tenderFinalDate, tenderInitialDate]);

  useEffect(() => {
    getVisits();
  }, [visitFinalDate, visitInitialDate]);

  useEffect(() => {
    getLeads();
  }, [leadFinalDate, leadInitialDate]);

  useEffect(() => {
    getMinutesConversion();
  }, [minuteFinalDate, minuteInitialDate]);

  useEffect(() => {
    getCapture();
  }, [captureFinalDate, captureInitialDate]);

  return (
    <>
      <Container>
        <FilterDate>
          <input
            type="date"
            value={vgvInitialDate}
            onChange={(e) => setVgvInitialDate(e.target.value)}
          />

          <span>até</span>

          <input
            type="date"
            value={vgvFinalDate}
            onChange={(e) => setVgvFinalDate(e.target.value)}
          />
        </FilterDate>

        <Title center>Volume Geral de Vendas (VGV)</Title>

        <ChartDiv>
          <ResponsiveBar
            data={vgv}
            keys={['vgv']}
            indexBy="date"
            margin={{ top: 20, right: 0, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            colors={{ datum: 'data.color' }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Mês',
              legendPosition: 'middle',
              legendOffset: 32,
            }}
            axisLeft={{
              tickValues: roundTo.up(vgvMax / vgvBreak, 0),
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Valor do VGV',
              legendPosition: 'middle',
              legendOffset: -50,
            }}
            maxValue={vgvMax}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="white"
            animate
            motionStiffness={90}
            motionDamping={15}
            enableGridX={false}
          />
        </ChartDiv>

        <ScaleFilter>
          <span>Intervalo:</span>
          <input
            type="number"
            value={vgvBreak}
            onChange={(e) => setVgvBreak(e.target.value)}
          />
          <span>Max:</span>
          <input
            type="number"
            value={vgvMax}
            onChange={(e) => setVgvMax(e.target.value)}
          />
        </ScaleFilter>
      </Container>

      <Container>
        <FilterDate>
          <input
            type="date"
            value={tenderInitialDate}
            onChange={(e) => setTenderInitialDate(e.target.value)}
          />

          <span>até</span>

          <input
            type="date"
            value={tenderFinalDate}
            onChange={(e) => setTenderFinalDate(e.target.value)}
          />
        </FilterDate>

        <Title center>Quantidade de Propostas</Title>

        <ChartDiv>
          <ResponsiveBar
            data={tender}
            keys={['tender']}
            indexBy="date"
            margin={{ top: 20, right: 0, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            colors={{ datum: 'data.color' }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Mês',
              legendPosition: 'middle',
              legendOffset: 32,
            }}
            axisLeft={{
              tickValues: roundTo.up(tenderMax / tenderBreak, 0),
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Qtd. de Propostas',
              legendPosition: 'middle',
              legendOffset: -50,
            }}
            maxValue={tenderMax}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="white"
            animate
            motionStiffness={90}
            motionDamping={15}
            enableGridX={false}
            tooltip={(tooltip) => (
              <span
                style={{
                  display: 'flex',
                }}
              >
                <p
                  style={{
                    height: '13px',
                    width: '13px',
                    background: tooltip.color,
                    marginRight: '7px',
                    marginBottom: '2px',
                    alignSelf: 'center',
                  }}
                />{' '}
                Propostas - {tooltip.indexValue}:{' '}
                <strong style={{ marginLeft: '5px' }}>{tooltip.value}</strong>
              </span>
            )}
          />
        </ChartDiv>

        <ScaleFilter>
          <span>Intervalo:</span>
          <input
            type="number"
            value={tenderBreak}
            onChange={(e) => setTenderBreak(e.target.value)}
          />
          <span>Max:</span>
          <input
            type="number"
            value={tenderMax}
            onChange={(e) => setTenderMax(e.target.value)}
          />
        </ScaleFilter>

        <hr />

        <Title center>Procentagem de Fechamento</Title>

        <ChartDiv>
          <ResponsiveLine
            data={closing}
            margin={{ top: 20, right: 50, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 0,
              max: 100,
              stacked: true,
              reverse: false,
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            colors="hsl(197, 100%, 24%)"
            axisBottom={{
              tickValues: 5,
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Mês',
              legendPosition: 'middle',
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Porcentagem',
              legendPosition: 'middle',
              legendOffset: -50,
            }}
            pointSize={10}
            pointColor={{ from: 'color', modifiers: [] }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh
          />
        </ChartDiv>
      </Container>

      <Container>
        <FilterDate>
          <input
            type="date"
            value={visitInitialDate}
            onChange={(e) => setVisitInitialDate(e.target.value)}
          />

          <span>até</span>

          <input
            type="date"
            value={visitFinalDate}
            onChange={(e) => setVisitFinalDate(e.target.value)}
          />
        </FilterDate>

        <Title center>Taxa de conversão de Leads, Visitas e Propostas</Title>

        <ChartDiv>
          <ResponsiveLine
            data={visit}
            margin={{ top: 20, right: 50, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 0,
              max: 100,
              stacked: true,
              reverse: false,
            }}
            colors={['#008891', '#0f3057']}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Mês',
              legendPosition: 'middle',
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Porcentagem',
              legendPosition: 'middle',
              legendOffset: -50,
            }}
            pointSize={10}
            pointColor={{ from: 'color', modifiers: [] }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh
          />
        </ChartDiv>

        <DivLegend>
          <div>
            <p style={{ background: '#008891' }} />
            <span>Conversão de Leads em Visitas</span>
          </div>

          <div>
            <p style={{ background: '#0f3057' }} />
            <span>Conversão de Visitas em Propostas</span>
          </div>
        </DivLegend>
      </Container>

      <Container>
        <FilterDate>
          <input
            type="date"
            value={leadInitialDate}
            onChange={(e) => setLeadInitialDate(e.target.value)}
          />

          <span>até</span>

          <input
            type="date"
            value={leadFinalDate}
            onChange={(e) => setLeadFinalDate(e.target.value)}
          />
        </FilterDate>

        <Title center>Quantidade de Leads</Title>

        <ChartDiv>
          <ResponsiveBar
            data={lead}
            keys={['lead']}
            indexBy="date"
            margin={{ top: 20, right: 0, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            colors={{ datum: 'data.color' }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Mês',
              legendPosition: 'middle',
              legendOffset: 32,
            }}
            axisLeft={{
              tickValues: roundTo.up(leadMax / leadBreak, 0),
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Quantidade de Leads',
              legendPosition: 'middle',
              legendOffset: -50,
            }}
            maxValue={leadMax}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="white"
            animate
            motionStiffness={90}
            motionDamping={15}
            enableGridX={false}
          />
        </ChartDiv>

        <ScaleFilter>
          <span>Intervalo:</span>
          <input
            type="number"
            value={leadBreak}
            onChange={(e) => setLeadBreak(e.target.value)}
          />
          <span>Max:</span>
          <input
            type="number"
            value={leadMax}
            onChange={(e) => setLeadMax(e.target.value)}
          />
        </ScaleFilter>

        <hr />

        <Title center>Média de Oferecimentos por Lead</Title>

        <ChartDiv>
          <ResponsiveBar
            data={lead}
            keys={['offerings']}
            indexBy="date"
            margin={{ top: 20, right: 0, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            colors={{ datum: 'data.color' }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Mês',
              legendPosition: 'middle',
              legendOffset: 32,
            }}
            axisLeft={{
              tickValues: 10,
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Qtd. de Oferecimentos por Lead',
              legendPosition: 'middle',
              legendOffset: -50,
            }}
            maxValue={10}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="white"
            animate
            motionStiffness={90}
            motionDamping={15}
            enableGridX={false}
            tooltip={(tooltip) => (
              <span
                style={{
                  display: 'flex',
                }}
              >
                <p
                  style={{
                    height: '13px',
                    width: '13px',
                    background: tooltip.color,
                    marginRight: '7px',
                    marginBottom: '2px',
                    alignSelf: 'center',
                  }}
                />{' '}
                Média - {tooltip.indexValue}:{' '}
                <strong style={{ marginLeft: '5px' }}>{tooltip.value}</strong>
              </span>
            )}
          />
        </ChartDiv>
      </Container>

      <Container>
        <FilterDate>
          <input
            type="date"
            value={minuteInitialDate}
            onChange={(e) => setMinuteInitialDate(e.target.value)}
          />

          <span>até</span>

          <input
            type="date"
            value={minuteFinalDate}
            onChange={(e) => setMinuteFinalDate(e.target.value)}
          />
        </FilterDate>

        <Title center>Tempo Médio por Lead</Title>

        <ChartDiv>
          <ResponsiveBar
            data={minute}
            keys={['minutes']}
            indexBy="date"
            margin={{ top: 20, right: 0, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            colors={{ datum: 'data.color' }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Mês',
              legendPosition: 'middle',
              legendOffset: 32,
            }}
            axisLeft={{
              tickValues: roundTo.up(minuteMax / minuteBreak, 0),
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Minutos',
              legendPosition: 'middle',
              legendOffset: -50,
            }}
            maxValue={minuteMax}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="white"
            animate
            motionStiffness={90}
            motionDamping={15}
            enableGridX={false}
            tooltip={(tooltip) => (
              <span
                style={{
                  display: 'flex',
                }}
              >
                <p
                  style={{
                    height: '13px',
                    width: '13px',
                    background: tooltip.color,
                    marginRight: '7px',
                    marginBottom: '2px',
                    alignSelf: 'center',
                  }}
                />{' '}
                Minutos - {tooltip.indexValue}:{' '}
                <strong style={{ marginLeft: '5px' }}>{tooltip.value}</strong>
              </span>
            )}
          />
        </ChartDiv>

        <ScaleFilter>
          <span>Intervalo:</span>
          <input
            type="number"
            value={minuteBreak}
            onChange={(e) => setMinuteBreak(e.target.value)}
          />
          <span>Max:</span>
          <input
            type="number"
            value={minuteMax}
            onChange={(e) => setMinuteMax(e.target.value)}
          />
        </ScaleFilter>

        <hr />

        <Title center>Porcentagem de Conversão</Title>

        <ChartDiv>
          <ResponsiveLine
            data={conversion}
            margin={{ top: 20, right: 50, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 0,
              max: 30,
              stacked: true,
              reverse: false,
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            colors="hsl(197, 100%, 24%)"
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Mês',
              legendPosition: 'middle',
              legendOffset: 32,
            }}
            axisLeft={{
              tickValues: 5,
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Porcentagem',
              legendPosition: 'middle',
              legendOffset: -50,
            }}
            pointSize={10}
            pointColor={{ from: 'color', modifiers: [] }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh
          />
        </ChartDiv>
      </Container>

      <Container>
        <FilterDate>
          <input
            type="date"
            value={captureInitialDate}
            onChange={(e) => setCaptureInitialDate(e.target.value)}
          />

          <span>até</span>

          <input
            type="date"
            value={captureFinalDate}
            onChange={(e) => setCaptureFinalDate(e.target.value)}
          />
        </FilterDate>

        <Title center>Captação</Title>

        <ChartDiv>
          <ResponsiveBar
            data={capture}
            keys={['capture']}
            indexBy="date"
            margin={{ top: 20, right: 0, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            colors={{ datum: 'data.color' }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Mês',
              legendPosition: 'middle',
              legendOffset: 32,
            }}
            axisLeft={{
              tickValues: roundTo.up(captureMax / captureBreak, 0),
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Quantidade',
              legendPosition: 'middle',
              legendOffset: -50,
            }}
            maxValue={captureMax}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="white"
            animate
            motionStiffness={90}
            motionDamping={15}
            enableGridX={false}
          />
        </ChartDiv>

        <ScaleFilter>
          <span>Intervalo:</span>
          <input
            type="number"
            value={captureBreak}
            onChange={(e) => setCaptureBreak(e.target.value)}
          />
          <span>Max:</span>
          <input
            type="number"
            value={captureMax}
            onChange={(e) => setCaptureMax(e.target.value)}
          />
        </ScaleFilter>
      </Container>
    </>
  );
}

ViewAll.propTypes = {
  match: PropTypes.object.isRequired,
};
