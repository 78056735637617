import styled from 'styled-components';

export const DivLogo = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  background: linear-gradient(#0f3057, #00587a);
  border-radius: 8px;

  img {
    width: 100%;
  }
`;
