/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import roundTo from 'round-to';

import api from '~/services/api';

import Container from '~/components/Default/Container';
import Loader from '~/components/Loader';
import Table from '~/components/Table';
import Pagination from '~/components/Pagination';
import CreateButton from '~/components/Form/Button/Create';
import EditButton from '~/components/Form/Button/Edit';
import DeleteButton from '~/components/Form/Button/Delete';
import Modal from '~/components/Modal/Delete';

export default function ListGroups() {
  const [groups, setGroups] = useState([]);
  const [loaderGroups, setLoaderGroups] = useState(true);
  const [deleteGroup, setDeleteGroup] = useState(null);
  const [showModalGroup, setShowModalGroup] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  function handleGroupModal(id) {
    setDeleteGroup(id);
    setShowModalGroup(!showModalGroup);
  }

  const changePage = (num) => {
    setPage(num + 1);
  };

  const getGroups = async () => {
    setLoaderGroups(true);

    try {
      const response = await api.get('/groups', {
        params: {
          page,
        },
      });

      setGroups(
        response.data.rows.map((group) => ({
          id: group.id,
          name: group.name,
          gestor: group.manager.name,
          edit: <EditButton url={`/groups/${group.id}/edit`} />,
          delete: <DeleteButton func={() => handleGroupModal(group.id)} />,
        }))
      );

      const numberOfPages = roundTo.up(response.data.count / 10, 0);

      setCount(numberOfPages);
    } catch (err) {
      toast.error('Erro ao carregar grupos!');
    } finally {
      setLoaderGroups(false);
    }
  };

  useEffect(() => {
    getGroups();
  }, [page]);

  async function handleDeleteGroup() {
    try {
      setShowModalGroup(false);
      setLoaderGroups(true);

      await api.delete(`/groups/${deleteGroup}`);

      setGroups(groups.filter((group) => group.id !== deleteGroup));

      toast.success('Groupo excluído com sucesso.');
    } catch (err) {
      toast.error('Erro ao excluir groupo, confira os dados!');
    } finally {
      setLoaderGroups(false);
      setDeleteGroup(null);
    }
  }

  const headerGroups = ['Nome', 'Gestor', 'Editar', 'Deletar'];

  return (
    <>
      <Modal
        show={showModalGroup}
        setState={setShowModalGroup}
        func={handleDeleteGroup}
      />

      <Container>
        <CreateButton url="/groups/create" text="Adicionar grupo" />

        {loaderGroups ? (
          <Loader />
        ) : (
          <>
            <Table
              title="Grupos"
              header={headerGroups}
              data={groups}
              size="500px"
            />

            <Pagination
              numberOfPages={count}
              activePage={page}
              changePage={changePage}
            />
          </>
        )}
      </Container>
    </>
  );
}
