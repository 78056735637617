import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const CustomButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height: 40px;
  ${(props) =>
    props.color &&
    css`
      background: ${props.color};
    `}
  border: 0;
  border-radius: 4px;
  transition: background 0.3s;

  &:hover {
    ${(props) =>
      props.color &&
      css`
        background: ${darken(0.07, props.color)};
      `}
  }
`;
