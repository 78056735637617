/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FiChevronDown } from 'react-icons/fi';
import { toast } from 'react-toastify';

import api from '~/services/api';

import logo from '~/assets/logo.png';

import { Container } from './styles';

export default function Header() {
  const [groups, setGroups] = useState([]);
  const { profile } = useSelector((state) => state.user);

  const toggleMenu = (el) => {
    el.target.classList.toggle('active');
  };

  const getGroups = async () => {
    try {
      const response = await api.get('/groups/all');

      setGroups(response.data);
    } catch (err) {
      toast.error('Erro ao carregar grupos.');
    }
  };

  useEffect(() => {
    getGroups();
  }, []);

  return (
    <Container id="menu">
      <div>
        <img src={logo} alt="logo" />
      </div>

      {profile.type === 'administrator' && (
        <>
          <li>
            <a onClick={(e) => toggleMenu(e)}>
              Gerenciar Grupos <FiChevronDown color="#fff" size={20} />
            </a>

            <div>
              <a onClick={(e) => toggleMenu(e)}>
                Visualizar <FiChevronDown color="#fff" size={20} />
              </a>

              <div>
                {groups &&
                  groups.map((group) => (
                    <a key={group.id} href={`/groups/${group.id}/data`}>
                      {group.name}
                    </a>
                  ))}
              </div>

              <Link to="/groups">Grupos</Link>
            </div>
          </li>

          <li>
            <a onClick={(e) => toggleMenu(e)}>
              Gerenciar Corretores <FiChevronDown color="#fff" size={20} />
            </a>

            <div>
              <Link to="/brokers">Corretores</Link>
              <Link to="/brokers/data">Inserir Dados</Link>
            </div>
          </li>

          <li>
            <Link to="/users">Gerenciar Usuários</Link>
          </li>

          <li>
            <Link to="/view-all">Visão Geral</Link>
          </li>
        </>
      )}

      {profile.type === 'manager' && (
        <>
          <li>
            <a onClick={(e) => toggleMenu(e)}>
              Grupos <FiChevronDown color="#fff" size={20} />
            </a>

            <div>
              {groups &&
                groups.map((group) => (
                  <a key={group.id} href={`/groups/${group.id}/data`}>
                    {group.name}
                  </a>
                ))}
            </div>
          </li>

          <li>
            <Link to="/brokers/data">Inserir Dados</Link>
          </li>
        </>
      )}

      {profile.type === 'broker' && (
        <li>
          <Link to={`/brokers/${profile.id}/data`}>Meus Dados</Link>
        </li>
      )}
    </Container>
  );
}
