import React from 'react';
import PropTypes from 'prop-types';

import { CreateLoader } from './styles';

export default function Loader({ size }) {
  return (
    <CreateLoader size={size}>
      <div />
    </CreateLoader>
  );
}

Loader.defaultProps = {
  size: '70px',
};

Loader.propTypes = {
  size: PropTypes.string,
};
