import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdList } from 'react-icons/md';

import { signOut } from '~/store/modules/auth/actions';

import { Container, Content } from './styles';

export default function Header() {
  const [menu, setMenu] = useState('open');
  const { profile } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  function handleSignOut() {
    dispatch(signOut());
  }
  return (
    <Container className={menu}>
      <Content>
        <div>
          <button
            type="button"
            onClick={() => setMenu(menu === 'open' ? '' : 'open')}
          >
            <MdList color="#002340" size={26} />
          </button>
        </div>

        <aside>
          <div>
            <strong>{profile.name}</strong>
            <Link to="/profile">Meu perfil</Link>
          </div>

          <button onClick={() => handleSignOut()}>Sair</button>
        </aside>
      </Content>
    </Container>
  );
}
