import React from 'react';
import PropTypes from 'prop-types';
import { MdClear } from 'react-icons/md';

import { CreateModal, Header, Content, Footer } from './styles';

export default function Modal({ show, setState, func, title, text }) {
  return (
    <CreateModal show={show}>
      <Header>
        <h2>{title}</h2>
        <MdClear color="#fff" size={20} onClick={() => setState(false)} />
      </Header>

      <Content>
        <p>{text}</p>
        <div />
      </Content>

      <Footer>
        <button onClick={() => func()}>Sim</button>
        <button onClick={() => setState(false)}>Não</button>
      </Footer>
    </CreateModal>
  );
}

Modal.defaultProps = {
  title: 'Excluir usuário',
  text: 'Realmente deseja excluir esse usuário?',
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  setState: PropTypes.func.isRequired,
  func: PropTypes.func.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
};
