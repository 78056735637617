import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import { signInRequest } from '~/store/modules/auth/actions';

import { DivLogo } from './styles';

import logo from '~/assets/logo.png';

const schema = Yup.object().shape({
  user: Yup.string().required('O usuário é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

function SignIn() {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  function handleSubmit() {
    dispatch(signInRequest(user, password));
  }
  return (
    <Form schema={schema} onSubmit={handleSubmit}>
      <DivLogo>
        <img src={logo} alt="Logo" />
      </DivLogo>

      <div>
        <Input
          type="text"
          name="user"
          value={user}
          onChange={(e) => setUser(e.target.value)}
        />
        <p className={user !== '' ? 'active' : ''}>Usuário</p>
      </div>

      <div>
        <Input
          type="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <p className={password !== '' ? 'active' : ''}>Senha</p>
      </div>

      <button
        type="submit"
        className={user !== '' && password !== '' ? 'enable' : 'disable'}
      >
        {loading ? 'Carregando...' : 'Acessar Minha Conta'}
      </button>
    </Form>
  );
}

export default SignIn;
