import React from 'react';
import PropTypes from 'prop-types';
import { IoIosAddCircleOutline } from 'react-icons/io';

import { CreateButton } from './styles';

export default function Create({ url, text }) {
  return (
    <CreateButton to={url}>
      <IoIosAddCircleOutline color="#fff" size={20} />
      {text}
    </CreateButton>
  );
}

Create.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
