import React from 'react';
import { toast } from 'react-toastify';
import { Form, Input, Select } from '@rocketseat/unform';
import * as Yup from 'yup';

import api from '~/services/api';
import history from '~/services/history';

import Container from '~/components/Default/Container';
import ReturnButton from '~/components/Form/Button/Return';

const schema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório.'),
  email: Yup.string().required('E-mail é obrigatório.'),
  user: Yup.string().required('Usuário é obrigatório.'),
  type: Yup.string().required('Tipo do usuário é obrigatório.'),
});

export default function CreateUser() {
  async function handleSubmit(data) {
    try {
      await api.post('/users', {
        ...data,
        password: '123456',
      });

      toast.success('Usuário cadastrado com sucesso.');
      history.push('/users');
    } catch (err) {
      toast.error('Erro ao cadastrar usuário, confira os dados!');
    }
  }

  return (
    <Container>
      <ReturnButton url="/users" text="Voltar" />

      <hr />

      <div>
        <Form schema={schema} onSubmit={handleSubmit}>
          <Input name="name" placeholder="Nome completo" />
          <Input name="email" type="email" placeholder="E-mail" />
          <Input name="user" placeholder="Usuário" />
          <Select
            name="type"
            placeholder="Selecione o tipo do usuário"
            options={[
              { id: 'administrator', title: 'Administrador' },
              { id: 'manager', title: 'Gestor' },
              { id: 'broker', title: 'Corretor' },
            ]}
          />

          <button type="submit">Cadastrar usuário</button>
        </Form>
      </div>
    </Container>
  );
}
