import React from 'react';
import PropTypes from 'prop-types';

import { CustomButton } from './styles';

export default function Custom({ func, text, children, color }) {
  return (
    <CustomButton onClick={func} color={color}>
      {children}
      {text}
    </CustomButton>
  );
}

Custom.defaultProps = {
  children: null,
  func: null,
  text: '',
  color: '#000000',
};

Custom.propTypes = {
  func: PropTypes.func,
  text: PropTypes.string,
  children: PropTypes.element,
  color: PropTypes.string,
};
