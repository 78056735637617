/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import roundTo from 'round-to';
import { AiOutlineEye } from 'react-icons/ai';

import api from '~/services/api';

import { CustomLink, DivButtons, InputText } from './styles';

import Container from '~/components/Default/Container';
import Loader from '~/components/Loader';
import Table from '~/components/Table';
import Pagination from '~/components/Pagination';
import CreateButton from '~/components/Form/Button/Create';
import EditButton from '~/components/Form/Button/Edit';
import DeleteButton from '~/components/Form/Button/Delete';
import Modal from '~/components/Modal/Delete';

export default function ListBrokers() {
  const [brokers, setBrokers] = useState([]);
  const [loaderBrokers, setLoaderBrokers] = useState(true);
  const [deleteBroker, setDeleteBroker] = useState(null);
  const [showModalBroker, setShowModalBroker] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);

  function handleBrokerModal(id) {
    setDeleteBroker(id);
    setShowModalBroker(!showModalBroker);
  }

  const changePage = (num) => {
    setPage(num + 1);
  };

  const getBrokers = async () => {
    setLoaderBrokers(true);

    try {
      const response = await api.get('/brokers', {
        params: {
          page,
          search,
        },
      });

      setBrokers(
        response.data.rows.map((broker) => ({
          id: broker.id,
          name: broker.user.name,
          ramal: broker.ramal,
          group: broker.group.name,
          view: (
            <CustomLink to={`/brokers/${broker.id}/data`}>
              <AiOutlineEye color="#fff" size={23} />
            </CustomLink>
          ),
          edit: <EditButton url={`/brokers/${broker.id}/edit`} />,
          delete: <DeleteButton func={() => handleBrokerModal(broker.id)} />,
        }))
      );

      const numberOfPages = roundTo.up(response.data.count / 10, 0);

      setCount(numberOfPages);
    } catch (err) {
      toast.error('Erro ao carregar grupos!');
    } finally {
      setLoaderBrokers(false);
    }
  };

  useEffect(() => {
    getBrokers();
  }, [page, search]);

  async function handleDeleteBroker() {
    try {
      setShowModalBroker(false);
      setLoaderBrokers(true);

      await api.delete(`/brokers/${deleteBroker}`);

      setBrokers(brokers.filter((broker) => broker.id !== deleteBroker));

      toast.success('Corretor excluído com sucesso.');
    } catch (err) {
      toast.error('Erro ao excluir corretor, confira os dados!');
    } finally {
      setLoaderBrokers(false);
      setDeleteBroker(null);
    }
  }

  const headerBrokers = [
    'Nome',
    'Ramal',
    'Grupo',
    'Visualizar',
    'Editar',
    'Deletar',
  ];

  return (
    <>
      <Modal
        show={showModalBroker}
        setState={setShowModalBroker}
        func={handleDeleteBroker}
      />

      <Container>
        <DivButtons>
          <CreateButton url="/brokers/create" text="Adicionar corretor" />

          <InputText
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </DivButtons>

        {loaderBrokers ? (
          <Loader />
        ) : (
          <>
            <Table
              title="Corretores"
              header={headerBrokers}
              data={brokers}
              size="500px"
            />

            <Pagination
              numberOfPages={count}
              activePage={page}
              changePage={changePage}
            />
          </>
        )}
      </Container>
    </>
  );
}
