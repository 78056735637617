import styled from 'styled-components';
import { lighten } from 'polished';
import { Link } from 'react-router-dom';

export const CreateButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  max-width: 220px;
  height: 40px;
  padding: 0 10px;
  font-size: 15px;
  font-weight: bold;
  background: ${lighten(0.2, '#002340')};
  border: 0;
  border-radius: 4px;
  margin-bottom: 20px;

  &:hover {
    background: ${lighten(0.1, '#002340')};
  }

  svg {
    margin-right: 10px;
  }
`;
