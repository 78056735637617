import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Form, Input, Select } from '@rocketseat/unform';
import * as Yup from 'yup';

import api from '~/services/api';
import history from '~/services/history';

import Container from '~/components/Default/Container';
import ReturnButton from '~/components/Form/Button/Return';

const schema = Yup.object().shape({
  name: Yup.string(),
  user_id: Yup.string(),
});

export default function EditGroups({ match }) {
  const [group, setGroup] = useState({});
  const [managers, setManagers] = useState([]);
  const [manager, setManager] = useState('');
  const [id] = useState(match.params.id ? match.params.id : false);

  const loadGroup = async () => {
    try {
      const response = await api.get(`/groups/${id}/details`);

      setGroup(response.data);
      setManager(response.data.user_id);
    } catch (err) {
      toast.error('Erro ao carregar grupo.');
    }
  };

  const loadManagers = async () => {
    try {
      const response = await api.get(`/users/managers`);

      setManagers(
        response.data.map((item) => ({
          id: item.id,
          title: `${item.name} - ${item.email}`,
        }))
      );
    } catch (err) {
      toast.error('Erro ao carregar gestores.');
    }
  };

  useEffect(() => {
    loadGroup();
    loadManagers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  async function handleSubmit(data) {
    try {
      await api.put(`/groups/${id}`, {
        ...data,
      });

      toast.success('Grupo atualizado com sucesso.');
      history.push('/groups');
    } catch (err) {
      toast.error('Erro ao atualizar grupo, confira os dados!');
    }
  }

  return (
    <Container>
      <ReturnButton url="/groups" text="Voltar" />

      <hr />

      <div>
        <Form schema={schema} initialData={group} onSubmit={handleSubmit}>
          <Input name="name" placeholder="Nome do grupo" />
          <Select
            name="user_id"
            placeholder="Gestor"
            value={manager}
            onChange={(e) => setManager(e.target.value)}
            options={managers}
          />

          <button type="submit">Atualizar grupo</button>
        </Form>
      </div>
    </Container>
  );
}

EditGroups.propTypes = {
  match: PropTypes.object.isRequired,
};
