import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  background: #fff;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#0f3057, #00587a);
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 407px;
  min-height: 415px;
  height: auto;
  padding: 40px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.5);

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    div {
      position: relative;
      width: 100%;

      &:hover p {
        transform: translateY(-25px);
      }

      & input:focus + p {
        transform: translateY(-25px);
      }

      .active {
        transform: translateY(-25px) !important;
      }

      input {
        width: 100%;
        margin-bottom: 30px;
        border: none;
        border-bottom: 2px solid #0f3057;
        outline: none;
        height: 40px;
        color: #000000;
        font-size: 15px;

        &:focus {
          border-bottom: 2px solid #00587a;
        }
      }

      span {
        color: #008891;
        text-align: center;
        font-weight: bold;
        top: -20px;
        position: relative;
      }

      p {
        font-weight: bold;
        color: #008891;
        position: absolute;
        top: 15%;
        left: 2px;
        transition: 1s all ease;
      }
    }

    button {
      width: 100%;
      height: 40px;

      background: #fff;
      color: #0f3057;
      font-size: 20px;
      font-family: 'montserrat';
      border: 2px solid #0f3057;
      border-radius: 30px;
      outline: none;
      overflow: hidden;
      position: relative;
      transform: translateY(100%);
      cursor: not-allowed;
      transition: border 1s ease;
      z-index: 0;
      opacity: 0.8;
      margin-bottom: 40px;

      &::before {
        content: '';
        width: 0;
        height: 110%;
        position: absolute;
        top: 50%;
        left: 50%;
        background: #0f3057;
        z-index: -1;
        transform: translate(-50%, -50%);
        transition: all 0.6s ease;
      }
    }

    .enable {
      color: #fff;
      cursor: pointer;
      border: 0.01px solid #00587a;
      opacity: 1;

      &::before {
        width: 120%;
      }
    }
  }
`;
