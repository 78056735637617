import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { darken } from 'polished';

export const CustomLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #008891;
  border: 0;
  border-radius: 4px;

  &:hover {
    background: ${darken(0.07, '#008891')};
  }
`;

export const DivButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  a {
    margin: 0 !important;
  }
`;

export const InputText = styled.input`
  background: #fff;
  border: 1px solid #333;
  border-radius: 4px;
  height: 44px;
  width: 100%;
  max-width: 200px;
  padding: 0 15px;
  color: #333;
  align-self: flex-end;

  &:focus {
    border: 1px solid #000000;
  }

  &::placeholder {
    color: #999;
  }
`;
