import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-top: 20px;
`;

export const PaginationButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px;
  width: 40px;
  background: #0f3057;
  font-weight: bold;
  color: #fff;
  border: 0;
  border-radius: 4px;
  transition: background 0.2s;

  & + button {
    margin-left: 15px;
  }

  &:hover {
    background: #fff;
    color: #0f3057;
    border: 1px solid #0f3057;
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
    background: #008891;
  }
`;
