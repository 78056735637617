import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { darken } from 'polished';

export const Title = styled.h1`
  font-size: 21px;
  line-height: 28px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: #323232;
  margin-bottom: 20px;
  align-self: ${(props) => (props.center ? 'center' : 'flex-start')};
  padding-left: 10px;
`;

export const FilterDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  align-self: center;
  margin-bottom: 20px;

  input {
    background: #fff;
    border: 1px solid ${darken(0.07, '#eee')};
    border-radius: 4px;
    height: 44px;
    width: 100%;
    max-width: 250px;
    padding: 0 15px;
    color: #333;
    margin: 0 0 10px;

    &:focus {
      border: 1px solid #000000;
    }

    &::placeholder {
      color: #999;
    }
  }

  input[type='datetime-local'],
  input[type='date'] {
    &::-webkit-clear-button,
    &::-webkit-inner-spin-button {
      display: none;
    }
  }
`;

export const CustomLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #008891;
  border: 0;
  border-radius: 4px;

  &:hover {
    background: ${darken(0.07, '#008891')};
  }
`;

export const ChartDiv = styled.div`
  height: 400px;
`;

export const ScaleFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  align-self: center;
  margin-top: 20px;

  input {
    background: #fff;
    border: 1px solid ${darken(0.07, '#eee')};
    border-radius: 4px;
    height: 40px;
    width: 100%;
    max-width: 100px;
    padding: 0 15px;
    color: #333;
    margin: 0 0 10px;

    &:focus {
      border: 1px solid #000000;
    }

    &::placeholder {
      color: #999;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const DivLegend = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 100%;
  max-width: 650px;
  margin: 20px auto 0;

  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    p {
      width: 15px;
      height: 15px;
      border-radius: 2px;
      margin-right: 15px;
    }

    span {
      font-size: 16px;
      line-height: 20px;
      font-family: 'Montserrat', sans-serif;
      color: #323232;
    }
  }
`;
