import React from 'react';
import { MdDeleteForever } from 'react-icons/md';
import PropTypes from 'prop-types';

import { DeleteButton } from './styles';

export default function Edit({ func }) {
  return (
    <DeleteButton onClick={func}>
      <MdDeleteForever color="#fff" size={23} />
    </DeleteButton>
  );
}

Edit.propTypes = {
  func: PropTypes.func.isRequired,
};
