import styled from 'styled-components';

export const Container = styled.div`
  width: 270px;
  min-width: 245px;
  max-height: 100vh;
  background: #0f3057;
  overflow-y: scroll !important;
  transition: width 0.3s linear 0.5s;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  & > div {
    width: 100%;
    height: 67px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(120deg, #008891, #00587a);

    img {
      width: 50%;
      min-width: 122px;
    }
  }

  li {
    overflow: hidden;
    min-height: 48px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    transition: background 0.3s;

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 16px 20px;
      color: #fff;
      line-height: 1.3em;
      font-size: 15px;
      font-weight: bold;
      position: relative;

      svg {
        transition: transform 0.5s;
      }
    }

    & > a:hover {
      background: #00587a;
    }

    & > a.active:hover {
      background: #0f3057;
    }

    a.active::after {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      background: #0f3057;
      left: 20px;
      bottom: -7px;
      transform: rotate(45deg);
      z-index: 1;
    }

    a.active svg {
      transform: rotate(180deg);
    }

    a + div {
      background: #00587a;
      overflow: hidden;
      transition: max-height 0.3s;
      max-height: 0;
    }

    a.active + div {
      max-height: 50em;
      width: 100%;
    }

    & > div {
      background: #00587a;
      overflow: hidden;
      transition: max-height 0.3s;
      max-height: 0;

      a + a::after {
        content: '';
        background: #fff;
        width: 80%;
        height: 1px;
        position: absolute;
        top: 0;
        left: 10%;
        transition: background 0.3s;
      }

      & > a::before {
        content: '';
        background: none;
        width: 3px;
        height: 90%;
        position: absolute;
        top: 5%;
        left: 1px;
        transition: background 0.3s;
      }

      & > a:hover::before {
        background: #008891;
      }

      & > a.active::after {
        background: #00587a;
      }

      a + div {
        background: #008891;
      }
    }
  }

  li + li::after {
    content: '';
    background: #008891;
    width: 80%;
    height: 1px;
    position: absolute;
    top: 0;
    left: 10%;
    transition: background 0.3s;
  }
`;
