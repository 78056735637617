import axios from 'axios';

const api = axios.create({
  baseURL: 'http://45.82.75.185:3333',
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
  },
});

export default api;
