import styled from 'styled-components';
import { lighten } from 'polished';

export const CreateLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  div {
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    display: inline-block;
    border: 3px solid ${lighten(0.1, '#999')};
    border-radius: 50%;
    border-top-color: #000000;
    animation: 1.2s spin infinite ease-in-out;
  }
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
