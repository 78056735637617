import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Form, Input, Select } from '@rocketseat/unform';
import * as Yup from 'yup';

import api from '~/services/api';
import history from '~/services/history';

import Container from '~/components/Default/Container';
import ReturnButton from '~/components/Form/Button/Return';

const schema = Yup.object().shape({
  name: Yup.string(),
  email: Yup.string(),
  user: Yup.string(),
  type: Yup.string(),
});

export default function EditUser({ match }) {
  const [user, setUser] = useState({});
  const [type, setType] = useState('');
  const [id] = useState(match.params.id ? match.params.id : false);

  const loadUser = async () => {
    try {
      const response = await api.get(`/users/${id}/details`);

      setUser(response.data);
      setType(response.data.type);
    } catch (err) {
      toast.error('Erro ao carregar usuário.');
    }
  };

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  async function handleSubmit(data) {
    try {
      await api.put(`/users/${id}`, {
        ...data,
      });

      toast.success('Usuário atualizado com sucesso.');
      history.push('/users');
    } catch (err) {
      toast.error('Erro ao atualizar usuário, confira os dados!');
    }
  }

  return (
    <Container>
      <ReturnButton url="/users" text="Voltar" />

      <hr />

      <div>
        <Form schema={schema} initialData={user} onSubmit={handleSubmit}>
          <Input name="name" placeholder="Nome completo" />
          <Input name="email" type="email" placeholder="E-mail" />
          <Input name="user" placeholder="Usuário" />
          <Select
            name="type"
            placeholder="Selecione o tipo do usuário"
            value={type}
            options={[
              { id: 'administrator', title: 'Administrador' },
              { id: 'manager', title: 'Gestor' },
              { id: 'broker', title: 'Corretor' },
            ]}
            onChange={(e) => setType(e.target.value)}
          />

          <button type="submit">Atualizar usuário</button>
        </Form>
      </div>
    </Container>
  );
}

EditUser.propTypes = {
  match: PropTypes.object.isRequired,
};
