import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Form, Input, Select } from '@rocketseat/unform';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import api from '~/services/api';
import history from '~/services/history';

import Container from '~/components/Default/Container';
import ReturnButton from '~/components/Form/Button/Return';

const schema = Yup.object().shape({
  ramal: Yup.number(),
  user_id: Yup.string(),
  group_id: Yup.string(),
});

export default function EditBroker({ match }) {
  const [broker, setBroker] = useState({});
  const [groups, setGroups] = useState([]);
  const [user, setUser] = useState('');
  const [group, setGroup] = useState('');
  const [availableBrokers, setAvailableBrokers] = useState([]);
  const [id] = useState(match.params.id ? match.params.id : false);

  const loadBroker = async () => {
    try {
      const response = await api.get(`/brokers/${id}/details`);

      setBroker({ ...response.data });
      setUser(response.data.user_id);
      setGroup(response.data.group_id);
    } catch (err) {
      toast.error('Erro ao carregar corretor.');
    }
  };

  const loadGroups = async () => {
    try {
      const response = await api.get(`/groups/all`);

      if (response.data.length === 0) {
        toast.warn('Não existem grupos disponíveis.');
      }

      setGroups(
        response.data.map((item) => ({
          id: item.id,
          title: item.name,
        }))
      );
    } catch (err) {
      toast.error('Erro ao carregar grupos.');
    }
  };

  const loadAvailableBrokers = async () => {
    try {
      const response = await api.get(`/brokers/available`);

      if (response.data.length === 0) {
        toast.warn('Não existem usuários disponíveis para serem corretores.');
      }

      setAvailableBrokers(
        response.data.map((item) => ({
          id: item.id,
          title: `${item.name} - ${item.email}`,
        }))
      );
    } catch (err) {
      toast.error('Erro ao carregar corretores.');
    }
  };

  useEffect(() => {
    loadBroker();
    loadAvailableBrokers();
    loadGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  async function handleSubmit(data) {
    try {
      await api.put(`/brokers/${id}`, {
        ...data,
      });

      toast.success('Corretor atualizado com sucesso.');
      history.push('/brokers');
    } catch (err) {
      toast.error('Erro ao atualizar corretor, confira os dados!');
    }
  }

  return (
    <Container>
      <ReturnButton url="/brokers" text="Voltar" />

      <hr />

      <div>
        <Form schema={schema} initialData={broker} onSubmit={handleSubmit}>
          <Input name="ramal" type="number" placeholder="Ramal" />
          <Select
            name="group_id"
            placeholder="Grupo"
            value={group}
            onChange={(e) => setGroup(e.target.value)}
            options={groups}
          />
          <Select
            name="user_id"
            placeholder="Usuário"
            value={user}
            onChange={(e) => setUser(e.target.value)}
            options={[
              {
                id: broker.user_id,
                title: broker.user
                  ? `${broker.user.name} - ${broker.user.email}`
                  : '',
              },
              ...availableBrokers,
            ]}
          />

          <button type="submit">Atualizar corretor</button>
        </Form>
      </div>
    </Container>
  );
}

EditBroker.propTypes = {
  match: PropTypes.object.isRequired,
};
