import React from 'react';
import PropTypes from 'prop-types';
import { MdModeEdit } from 'react-icons/md';

import { EditButton } from './styles';

export default function Edit({ url }) {
  return (
    <EditButton to={url}>
      <MdModeEdit color="#fff" size={20} />
    </EditButton>
  );
}

Edit.propTypes = {
  url: PropTypes.string.isRequired,
};
