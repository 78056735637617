import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import api from '~/services/api';

import { signOut } from '~/store/modules/auth/actions';

import SideHeader from '~/components/Header/Side';
import TopHeader from '~/components/Header/Top';

import { Wrapper, Body, Container, Main } from './styles';

export default function DefaultLayout({ children }) {
  const dispatch = useDispatch();

  const checkToken = async () => {
    try {
      await api.get('/check-token');
    } catch (err) {
      dispatch(signOut());
    }
  };

  useEffect(() => {
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <SideHeader />
      <Body>
        <TopHeader />

        <Container>
          <Main>{children}</Main>
        </Container>
      </Body>
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
