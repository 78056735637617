import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import api from '~/services/api';
import history from '~/services/history';

import Container from '~/components/Default/Container';

const schema = Yup.object().shape({
  minutes: Yup.number(),
  leads: Yup.number(),
  offerings: Yup.number(),
  visits: Yup.number(),
  tenders: Yup.number(),
  subscriptions: Yup.number(),
  capture: Yup.number(),
  vgv: Yup.number(),
  date: Yup.date(),
});

export default function EditBrokerData({ match }) {
  const [datas, setData] = useState({});
  const [name, setName] = useState('');
  const [id] = useState(match.params.id ? match.params.id : false);

  const loadData = async () => {
    try {
      const response = await api.get(`/brokers/${id}/data-details`);

      setData(response.data);
      setName(response.data.broker.user.name);
    } catch (err) {
      toast.error('Erro ao carregar dados.');
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  async function handleSubmit(data) {
    try {
      await api.put(`/brokers/${id}/data`, {
        ...data,
      });

      toast.success('Dados diários cadastrados com sucesso.');
      history.goBack();
    } catch (err) {
      toast.error('Erro ao cadastrar dados diários, confira os dados!');
    }
  }

  return (
    <Container>
      <div>
        <Form schema={schema} initialData={datas} onSubmit={handleSubmit}>
          <Input
            name="broker"
            value={`Corretor(a): ${name}`}
            readOnly
            disabled
          />
          <Input name="minutes" type="number" placeholder="Minutos" />
          <Input name="leads" type="number" placeholder="Leads" />
          <Input name="offerings" type="number" placeholder="Oferecimentos" />
          <Input name="visits" type="number" placeholder="Visitas" />
          <Input name="tenders" type="number" placeholder="Propostas" />
          <Input name="subscriptions" type="number" placeholder="Assinaturas" />
          <Input name="capture" type="number" placeholder="Captação" />
          <Input name="vgv" type="number" placeholder="VGV" />
          <Input name="date" type="date" placeholder="Data" />

          <button type="submit">Atualizar dados diários</button>
        </Form>
      </div>
    </Container>
  );
}

EditBrokerData.propTypes = {
  match: PropTypes.object.isRequired,
};
