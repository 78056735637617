import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  padding: 0 30px;
  height: 70px;
  border-bottom: 3px solid #008891;

  @media (max-width: 820px) {
    padding: 10px 20px;
    height: 110px;
  }

  @media (max-width: 540px) {
    height: 70px;
  }

  @media (max-width: 450px) {
    height: 120px;
  }
`;

export const Content = styled.div`
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 820px) {
    flex-direction: column;
  }

  & > div:first-child {
    min-width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    button {
      background: none;
      border: none;
    }
  }

  aside {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 170px;
      padding-right: 15px;
      margin-right: 15px;
      border-right: 1px solid #999;

      strong {
        display: block;
        color: #323232;
        align-self: flex-end;
      }

      a {
        display: block;
        margin-top: 2px;
        font-size: 12px;
        color: #999;
        align-self: flex-end;
      }
    }

    & > button {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      width: 60px;
      height: 32px;
      border-radius: 4px;
      text-align: center;
      background: #fff;
      border: 1px solid #008891;
      color: #008891;
      font-weight: bold;
      position: relative;
      transition: 1s all ease;
      z-index: 0;
      overflow: hidden;
      align-self: center;

      &:hover {
        color: #fff;
      }

      &::before {
        content: '';
        width: 110%;
        height: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        background: #008891;
        z-index: -1;
        transform: translate(-50%, -50%) rotate(45deg);
        transition: all 0.6s ease;
      }

      &:hover::before {
        height: 200%;
      }
    }
  }
`;
