import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: ${(props) => props.minHeight};
  background: #fff;
  border-radius: 4px;
  padding: 30px;

  form {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;

    button {
      align-self: flex-end;
      margin: 5px 0 0;
      height: 44px;
      min-width: 130px;
      padding: 0 10px;
      background: ${lighten(0.2, '#228b22')};
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 14px;
      transition: background 0.2s;

      &:hover {
        background: ${lighten(0.1, '#228b22')};
      }
    }
  }
`;
