import React from 'react';

import Container from '~/components/Default/Container';

import { Description } from './styles';

export default function Dashboard() {
  return (
    <Container minHeight="190px">
      <Description>
        <h1>Seja Bem-Vindo ao Sistema de Relatórios de Promotores</h1>

        <p>Descrição do sistema.</p>
      </Description>
    </Container>
  );
}
