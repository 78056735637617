import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Form, Input, Select } from '@rocketseat/unform';
import * as Yup from 'yup';

import api from '~/services/api';
import history from '~/services/history';

import Container from '~/components/Default/Container';

const schema = Yup.object().shape({
  broker_id: Yup.number().required(),
  minutes: Yup.number().required(),
  leads: Yup.number().required(),
  offerings: Yup.number().required(),
  visits: Yup.number().required(),
  tenders: Yup.number().required(),
  subscriptions: Yup.number().required(),
  capture: Yup.number().required(),
  vgv: Yup.number().required(),
  date: Yup.date().required(),
});

export default function CreateBrokerData() {
  const [brokers, setBrokers] = useState([]);

  const loadBrokers = async () => {
    try {
      const response = await api.get(`/brokers/all`);

      setBrokers(
        response.data.map((broker) => ({
          id: broker.id,
          title: `${broker.user.name} - ${broker.user.email}`,
        }))
      );
    } catch (err) {
      toast.error('Erro ao carregar corretores.');
    }
  };

  useEffect(() => {
    loadBrokers();
  }, []);

  async function handleSubmit(data) {
    try {
      await api.post(`/brokers/data`, {
        ...data,
      });

      toast.success('Dados diários cadastrados com sucesso.');
      history.push('/brokers');
    } catch (err) {
      toast.error('Erro ao cadastrar dados diários, confira os dados!');
    }
  }

  return (
    <Container>
      <div>
        <Form schema={schema} onSubmit={handleSubmit}>
          <Select name="broker_id" placeholder="Corretor" options={brokers} />
          <Input name="minutes" type="number" placeholder="Minutos" />
          <Input name="leads" type="number" placeholder="Leads" />
          <Input name="offerings" type="number" placeholder="Oferecimentos" />
          <Input name="visits" type="number" placeholder="Visitas" />
          <Input name="tenders" type="number" placeholder="Propostas" />
          <Input name="subscriptions" type="number" placeholder="Assinaturas" />
          <Input name="capture" type="number" placeholder="Captação" />
          <Input name="vgv" type="number" placeholder="VGV" />
          <Input name="date" type="date" placeholder="Data" />

          <button type="submit">Cadastrar dados diários</button>
        </Form>
      </div>
    </Container>
  );
}
