import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Form, Input, Select } from '@rocketseat/unform';
import * as Yup from 'yup';

import api from '~/services/api';
import history from '~/services/history';

import Container from '~/components/Default/Container';
import ReturnButton from '~/components/Form/Button/Return';

const schema = Yup.object().shape({
  ramal: Yup.number().required(),
  user_id: Yup.string().required(),
  group_id: Yup.string().required(),
});

export default function CreateBroker() {
  const [groups, setGroups] = useState([]);
  const [availableBrokers, setAvailableBrokers] = useState([]);

  const loadGroups = async () => {
    try {
      const response = await api.get(`/groups/all`);

      if (response.data.length === 0) {
        toast.warn('Não existem grupos disponíveis.');
      }

      setGroups(
        response.data.map((broker) => ({
          id: broker.id,
          title: broker.name,
        }))
      );
    } catch (err) {
      toast.error('Erro ao carregar grupos.');
    }
  };

  const loadAvailableBrokers = async () => {
    try {
      const response = await api.get(`/brokers/available`);

      if (response.data.length === 0) {
        toast.warn('Não existem usuários disponíveis para serem corretores.');
      }

      setAvailableBrokers(
        response.data.map((broker) => ({
          id: broker.id,
          title: `${broker.name} - ${broker.email}`,
        }))
      );
    } catch (err) {
      toast.error('Erro ao carregar corretores.');
    }
  };

  useEffect(() => {
    loadAvailableBrokers();
    loadGroups();
  }, []);

  async function handleSubmit(data) {
    try {
      await api.post(`/brokers`, {
        ...data,
      });

      toast.success('Corretor cadastrado com sucesso.');
      history.push('/brokers');
    } catch (err) {
      toast.error('Erro ao cadastrar corretor, confira os dados!');
    }
  }

  return (
    <Container>
      <ReturnButton url="/brokers" text="Voltar" />

      <hr />

      <div>
        <Form schema={schema} onSubmit={handleSubmit}>
          <Input name="ramal" type="number" placeholder="Ramal" />
          <Select name="group_id" placeholder="Grupo" options={groups} />
          <Select
            name="user_id"
            placeholder="Usuário"
            options={availableBrokers}
          />

          <button type="submit">Cadastrar corretor</button>
        </Form>
      </div>
    </Container>
  );
}
